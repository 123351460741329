
import { createRef, Component } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseApp } from "./firebaseConfig";

const app = firebaseApp
const analytics = getAnalytics(app);

export class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navCollapsed: true,
      width: window.innerWidth
    };
   
    this.myRef = createRef();
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.isNavCollapsed = this.isNavCollapsed.bind(this);
    this.handleNavCollapse = this.handleNavCollapse.bind(this);
  }

  isNavCollapsed() {
    //console.log("get state " + this.state.navCollapsed)
    return this.state.navCollapsed
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleNavCollapse() {
    console.log("Handle nav " + this.state.navCollapsed)
    this.setState({navCollapsed: true})
  }

  handleDocumentClick(e) {
 
    const container = this.state.navref;
    //console.log("Click " + this.state.navref)

    if (e.target !== container && !container.contains(e.target)) {
      console.log("Click nav state " + this.state.navCollapsed)
      this.setState({navCollapsed: false})
    }
  }
  
  componentDidMount() {
    // document.addEventListener('click', this.handleDocumentClick, true)

    // //console.log("Component mount" + this.myRef.current)
    // this.setState({navref: this.myRef.current}, () => {
    //   console.log(this.state.navref)}
    // )
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
  //  document.removeEventListener('click', this.handleDocumentClick, true)
     window.removeEventListener('resize', this.handleWindowSizeChange);
  }


  render() {
    const  handleEvent = (context) => {
        //console.log("Nav event before " + context)
        logEvent(analytics, 'navigation', {
            name: context
          });
        console.log("Nav event sent " + context)
      }

      const  handlePhone = (context) => {
        //console.log("Nav event before " + context)
        logEvent(analytics, 'goal_completion', {
            name: context
          });
        console.log("Nav event sent " + context)
      }
    const renderTel = () => {
      if (isMobile) 
      {
        return  <span> <i className='fa fa-phone'></i> <a href="tel:0723513564" onClick={() => handlePhone("phone_call")}> {this.props.data ? this.props.data.phone : 'loading'}</a></span>
      } else {
        return <span> <i className='fa fa-phone navbar-default'></i>  {this.props.data ? this.props.data.phone : 'loading'}</span>
      }
    }

    const { width } = this.state;
    const isMobile = width <= 500;

    return (
      <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
        <div className='container'>
          <div className='navbar-header'>
            <button
              type='button'
              className='navbar-toggle collapsed'
              data-toggle='collapse'
              data-target='#bs-example-navbar-collapse-1'
              aria-controls="bs-example-navbar-collapse-1" aria-expanded="false" aria-label="Toggle navigation"
              //onClick={this.handleNavCollapse}
            >
              {' '}
              <span className='sr-only'>Toggle navigation</span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
            </button>
            <div class="d-flex flex-nowrap w-100">
            <a className='navbar-brand page-scroll' href='#page-top' onClick={() => handleEvent("home")}>
              Medclar 
            </a>
            {renderTel()} 
            </div>
            {' '}
          </div>
          { this.isNavCollapsed() &&
          <div
            className='collapse navbar-collapse'
            id='bs-example-navbar-collapse-1'
            ref={this.myRef}
          >
            <ul className='nav navbar-nav navbar-right'>
  
              <li>
                <a href='#about' className='page-scroll' data-toggle="collapse" data-target=".in" onClick={() => handleEvent("about")}>
                  Despre
                </a>
              </li>
              <li>
                <a href='#services' className='page-scroll' data-toggle="collapse" data-target=".in" onClick={() => handleEvent("services")}>
                  Servicii
                </a>
              </li>
              <li>
                <a href='#contact' className='page-scroll' data-toggle="collapse" data-target=".in" onClick={() => handleEvent("contact")} >
                  Contact
                </a>
              </li>
            </ul>
          </div>
          }
        </div>
      </nav>
    )
  }
}

