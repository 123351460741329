
// Your web app's Firebase configuration

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAKz6IXb0gGrsF65_WaABIJK7PuP7q1WAY",
  authDomain: "medclar-1fa6a.firebaseapp.com",
  databaseURL: "https://medclar-1fa6a.firebaseio.com",
  projectId: "medclar-1fa6a",
  storageBucket: "medclar-1fa6a.appspot.com",
  messagingSenderId: "85549130852",
  appId: "1:85549130852:web:c6a6c81acb534114b46b71",
  measurementId: "G-RME3FHC0EP"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

