import { useState } from 'react'
import emailjs from 'emailjs-com'


import { getFirestore } from "firebase/firestore";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseApp } from "./firebaseConfig";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const app = firebaseApp
const db = getFirestore(app);
const analytics = getAnalytics(app);

const initialState = {
  name: '',
  email: '',
  message: '',
  consent: false,
}
export const Contact = (props) => {
  const [{ name, email, message, consent }, setState] = useState(initialState)

  const handleChange = (e) => {
    e.target.setCustomValidity('')
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const onInvalid = (e) => {
    if (e.target.type == 'checkbox') {
      e.target.setCustomValidity('Va rugam sa confirmati ca sunteti de acord sa folosim datele personale introduse')
    }
    else  {
        if (e.target.type == 'email') { 
              e.target.setCustomValidity('Va rugam introduceti un mail valid') 
        }
        else {
          e.target.setCustomValidity('Va rugam introduceti acest camp') 
        }
    }
  }


  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()

    console.log(name, email, message)
    let obj = {
            name : name,
            email:email,
            message:message,
            data: serverTimestamp(),
        }       
      try {
        const docRef =  addDoc(collection(db, "users"), obj);
        alert('Formularul a fost trimis cu success');
        console.log("Document written with ID: ", docRef.id);
        logEvent(analytics, 'goal_completion', {
          name: 'form_submit_succes'
        });

      } catch (e) {
        console.error("Error adding document: ", e);
        alert('A aparut o eroare la transmiterea formularului');
        logEvent(analytics, 'goal_completion', {
          name: 'form_submit_error'
        });
      }
        
    
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Contact</h2>
                <p>
                  Va rugam completati formularul de mai jos si va vom contacta in cel mai scurt timp.
                </p>
              </div>
              <form name='sentMessage' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6 col-xs-11'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Nume'
                        required
                        onChange={handleChange}
                        onInvalid={onInvalid}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6 col-xs-11'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                        onInvalid={onInvalid}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='row'>
                <div className='form-group'>
                <div className='col-md-12 col-xs-11'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Mesaj'
                    required
                    onChange={handleChange} onInvalid={onInvalid}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                </div>
                </div>
                <div className='row'>
                <div className='form-group'>
                <div className='col-md-12'>
                  <div className='col-md-1 col-xs-2' >
                      <input
                          class = "form-control"
                          type='checkbox'
                          id='consent'
                          name='consent'
                          className='form-control'
                          placeholder='Consimtamint'
                          onChange={handleChange}
                          required
                          onInvalid={onInvalid}
                      />
                    </div>
                    <div className='col-md-11 col-xs-9'>
                      <label for="consent" class="form-check-label">Sunt de acord ca datele personale introduse in acest formular sa fie stocate in vederea  realizarii raspunsului la aceasta cerere.                        
                      </label>
                    </div>
                    <p className='help-block text-danger'></p>
                </div>
                </div>
                </div>

                <div className='form-group'>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Trimite
                </button>
                </div>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Informatii Contact</h3>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Telefon
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
        </div>
      </div>
    </div>
  )
}
