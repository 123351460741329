
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseApp } from "./firebaseConfig";

const app = firebaseApp
const analytics = getAnalytics(app);




export const Header = (props) => {
  const handleEvent = (e) => {
   
    logEvent(analytics, 'navigation', {
            name: "more"
          });
    console.log("Nav event sent : More")
  }

  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='"col-xs-11 col-md-6" intro-text'>
                <h2>
                  {props.data ? props.data.title : 'Loading'}
                  
                </h2>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                <a
                  href='#about'
                  className='btn btn-custom btn-lg page-scroll'
                  onClick={() => handleEvent()}
                >
                  Mai multe
                </a>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
